import React from "react"
// import Helmet from "react-helmet"
// import { withPrefix, Link } from "gatsby"
import Layout from "../components/Layout"
// import About from "../components/Home/About"
// import Vision from "../components/Home/Vision"
// import Mission from "../components/Home/Mission"
// import GuidingPrinciple from "../components/Home/GuidingPrinciple"
// import PhilosophyOfCare from "../components/Home/PhilosophyOfCare"
// import SerenityCourageWisdom from "../components/Home/SerenityCourageWisdom"
// import Services from "../components/Home/Services"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import Wave from "../components/Wave"
// import Section from "../components/Section"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"
import "../css/index.css"

export default ({ data }) => (
  <>
    <Layout>

      <SEO title="Home" />
      <StyledHero home="true" img={data.images1.childImageSharp.fluid}>
        <div>
          {/* <div className="Serenity>"> */}
          <div className="Serenity1">
            Serenity One Hospice & Palliative Care
          </div>
          {/* </div> */}

          {/* <div className="Care>"> */}
          <div className="Care1">
            caring with comfort and dignity for patient and family
          </div>
          {/* </div> */}

          {/* <div className="WeProvide>"> */}
          <div className="WeProvide1">
            We provide holistic care for patient and family so they can journey
            through the process of losing a loved one with Comfort, Dignity and
            Serenity.
          </div>
          {/* </div> */}

          {/* <div className="bottom">
            <Wave />
          </div> */}
          {/* <Section image="/images/serenity-hero2.jpg" /> */}
        </div>
      </StyledHero>
    </Layout>
    {/* <About />
    <Mission />
    <Vision />
    <GuidingPrinciple />
    <PhilosophyOfCare />
    <SerenityCourageWisdom /> */}
  </>
)
export const query = graphql`
  query {
    images1: file(relativePath: { eq: "serenity-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
